const input = {
    username: 'Имя пользователя',
    password: 'Пароль',
    email: 'Электронная почта',
    phone_number: 'Номер телефона',
    confirm_password: 'Подтверждение пароля',
    search_region_code: 'Поиск кода региона',
    backup_code: 'Резервный код',
};
export default Object.freeze(input);
