const user_scopes = {
    descriptions: {
        /** UNTRANSLATED */
        custom_data: 'Your custom data',
        /** UNTRANSLATED */
        email: 'Your email address',
        /** UNTRANSLATED */
        phone: 'Your phone number',
        /** UNTRANSLATED */
        profile: 'Your name, username, avatar, and other profile info',
        /** UNTRANSLATED */
        roles: 'Your roles',
        /** UNTRANSLATED */
        identities: 'Your linked social identities',
        /** UNTRANSLATED */
        'urn:logto:scope:organizations': 'Your organizations info',
        /** UNTRANSLATED */
        'urn:logto:scope:organization_roles': 'Your organization roles',
        /** UNTRANSLATED */
        address: 'Your address',
    },
};
export default Object.freeze(user_scopes);
