const action = {
    sign_in: 'Войти',
    continue: 'Продолжить',
    create_account: 'Создать аккаунт',
    create_account_without_linking: 'Создать новый вместо этого',
    create: 'Создать',
    enter_passcode: 'Введите код подтверждения',
    confirm: 'Подтвердить',
    cancel: 'Отменить',
    save_password: 'Сохраните пароль',
    bind: 'Привязать к {{address}}',
    bind_and_continue: 'Привязать и продолжить',
    back: 'Назад',
    nav_back: 'Назад',
    nav_skip: 'Пропустить',
    agree: 'Согласен',
    got_it: 'Понял',
    sign_in_with: 'Войти через {{name}}',
    forgot_password: 'Забыли пароль?',
    switch_to: 'Изменить на {{method}}',
    sign_in_via_passcode: 'Войти с кодом подтверждения',
    sign_in_via_password: 'Войти с паролем',
    change: 'Изменить {{method}}',
    link_another_email: 'Привязать другую почту',
    link_another_phone: 'Привязать другой номер',
    link_another_email_or_phone: 'Привязать другую почту или номер',
    show_password: 'Показать пароль',
    copy: 'Копировать',
    verify_via_passkey: 'Проверить с помощью ключа доступа',
    download: 'Скачать',
    single_sign_on: 'Единый вход',
    /** UNTRANSLATED */
    authorize: 'Authorize',
    /** UNTRANSLATED */
    use_another_account: 'Use another account',
};
export default Object.freeze(action);
